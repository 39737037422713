import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { forEach } from 'lodash';

@Injectable()
export class BreadcrumbService {
  private attributeName = 'original-content';
  constructor(private translate: TranslateService, private router: Router) {
    this.translate.onLangChange.subscribe(() => this.translateBreadcrumb());
      this.router.events
        .pipe(
            filter(event => event instanceof NavigationEnd)
        )
        .subscribe(() => {
            setTimeout(() => this.translateBreadcrumb(), 350);
        });
  }

  private translateBreadcrumb(): void {
      const items = document.querySelectorAll('.breadcrumb-item a, .breadcrumb-item span');
      forEach(items, i => {
          if (!i.hasAttribute(this.attributeName)) {
            i.setAttribute(this.attributeName, i.innerHTML);
          }
          i.innerHTML = this.translate.instant(i.getAttribute(this.attributeName)) as string;
      });
  }
}
