import { EndPoints } from '@core/enums/endpoints';
import { StringEnum } from '@core/enums/string-enum-type';

export class XoffEndpoints extends StringEnum {
    public static controllerUrl = new XoffEndpoints(
        EndPoints.webApiBaseUrl.concat(EndPoints.webApiVersion1).add('xoff').toString(),
    );

    public static postUrl = new XoffEndpoints(XoffEndpoints.controllerUrl.toString());

    public static postAnnouncement = new XoffEndpoints(XoffEndpoints.controllerUrl.add('announcement').toString());
}
