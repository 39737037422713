import { MockResponse } from '@vueling-ng/vy-api-mocks';
import { XoffEndpoints } from './xoff.endpoints';

export const xoffResponses: MockResponse[] = [
    {
        url: XoffEndpoints.postAnnouncement.toString(),
        response: {
            data: {
                code: 200,
                message: 'OK',
            },
        },
    },
];
