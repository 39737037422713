import { DocumentsAimsEndPoints } from './aims/aims.endpoints';
import { DocumentsEndPoints } from './document-type/document-type.endpoints';
import { MockResponse } from '@vueling-ng/vy-api-mocks';
import * as moment from 'moment';
import { CrewDocumentEndPoints } from './document/document.endpoints';
import { DocumentStatus } from '../enums/document-status';
import { HttpMethods } from '@core/enums/http-methods';
import { CrewDocumentFilesEndPoints } from './files/crew-document-file.endpoint';
import { DocumentTypeFilesEndPoints } from './document-type-files/document-type-file.endpoint';
import { DocumentSpecializationEndPoints } from './document-specialization/document-specialization.endpoint';
import { CrewDocumentDetailEndPoints } from './document-detail/crew-document-detail.endpoint';

export const documentationMockResponses: MockResponse[] = [
    {
        url: DocumentsEndPoints.searchUrl.toString(),
        response: {
            data: [
            {
                id: 1,
                crewRanks: [ 1, 2 ],
                bases: [ 'BCN', 'AGP' ],
                name: 'TestDocuemntType',
                numberOfRequiredImages: 1,
                daysUntilExpirationAlert: 30,
                aimsFolder: 'X:\\Aims\\BlaBlaBla\\Test Documents',
                relatedExpiry: null,
                isDocumentNumberRequired: true,
                isExpeditionDateRequired: true,
                isExpireDateRequired: false,
                isExpeditionCountryRequired: false,
                creationDate: moment.utc(),
                updateDate:  moment.utc(),
            }],
            paging: {
                page: 1,
                pageCount: 1,
                totalRecordCount: 1
            }
        }
    },
    {
        url: DocumentsEndPoints.postUrl.toString(),
        method: HttpMethods.POST,
        response: {
            id: 1,
            crewRanks: [ 1, 2 ],
            bases: [ 'BCN', 'AGP' ],
            name: 'TestDocuemntType',
            numberOfRequiredImages: 1,
            daysUntilExpirationAlert: 30,
            aimsFolder: '/Test Documents',
            relatedExpiry: null,
            isDocumentNumberRequired: true,
            isExpeditionDateRequired: true,
            isExpireDateRequired: false,
            isExpeditionCountryRequired: false,
            creationDate: moment.utc(),
            updateDate:  moment.utc(),
        },
    },
    {
        url: DocumentsEndPoints.controllerUrl.add("1").toString(),
        response: {
            id: 1,
            crewRanks: [ 1, 2 ],
            bases: [ 'BCN', 'AGP' ],
            name: 'TestDocuemntType',
            numberOfRequiredImages: 1,
            daysUntilExpirationAlert: 30,
            aimsFolder: '/Test Documents',
            relatedExpiry: null,
            isDocumentNumberRequired: true,
            isExpeditionDateRequired: true,
            isExpireDateRequired: false,
            isExpeditionCountryRequired: false,
            creationDate: moment.utc(),
            updateDate:  moment.utc(),
        },
    },
    {
        url: DocumentsEndPoints.postUrl.add('1').add('delete').toString(),
        response: {},
    },
    {
        url: DocumentsAimsEndPoints.expiriesUrl.toString(),
        response: [
            {
                expiryId: 1,
                description: 'Expiry QWERTY'
            },
            {
                expiryId: 2,
                description: 'Expiry ASDFGH',
            },
            {
                expiryId: 3,
                description: 'Expiry ZXCVBN',
            }
        ],
    },
    {
        url: CrewDocumentEndPoints.searchUrl.toString(),
        response: {
            data: [
            {
                id: 1,
                crewId: 1014,
                documentTypeId: 1,
                documentType: 'TestDocuemntType',
                documentGroupId: '5bb80a25-d9cb-4919-abeb-f1f54b9ce065',
                expeditionDate: moment.utc(),
                expireDate: moment.utc(),
                expeditionCountry: 'SPAIN',
                documentNumber: '123456',
                crewInfo: {
                    crewId: 1014,
                    alias: 'TEST1014',
                    baseId: 'ORY',
                    rank: 1
                },
                documentFileIds: [],
                documentStatus: DocumentStatus.REVISION,
                creationDate: moment.utc(),
                updateDate: moment.utc()

            }],
            paging: {
                page: 1,
                pageCount: 1,
                totalRecordCount: 1
            }
        }
    },
    {
        url: CrewDocumentEndPoints.postUrl.toString(),
        response: {
            id: 1,
            crewId: 1014,
            documentTypeId: 1,
            documentType: 'TestDocuemntType',
            documentGroupId: '5bb80a25-d9cb-4919-abeb-f1f54b9ce065',
            expeditionDate: moment.utc(),
            expireDate: moment.utc(),
            expeditionCountry: 'SPAIN',
            documentNumber: '123456',
            crewInfo: {
                crewId: 1014,
                alias: 'TEST1014',
                baseId: 'BCN',
                rankId: 1
            },
            documentFileIds: [],
            documentStatus: DocumentStatus.REVISION,
            creationDate: moment.utc(),
            updateDate: moment.utc()

        },
    },
    {
        url: DocumentsEndPoints.controllerUrl.toString(),
        method: HttpMethods.GET,
        response: [{
            id: 1,
            name: 'Test document',
            crewRanks: [ 1, 2 ],
            bases: [ 'BCN', 'AGP' ],
        }],
    },
    {
        url: CrewDocumentFilesEndPoints.forDocumentUrl.add('1').toString(),
        method: HttpMethods.GET,
        response: [
            {
                id: 1,
                documentTypeFileId: 1,
                guid: '86c7e86c-3971-441d-b40f-bbfdab55c3d2'
            }
        ]
    },
    {
        url: CrewDocumentFilesEndPoints.forDocumentUrl.add('2').toString(),
        method: HttpMethods.GET,
        response: [
            {
                id: 1,
                documentTypeFileId: 2,
                guid: '86c7e86c-3971-441d-b40f-bbfdab55c3d2'
            }
        ]
    },
    {
        url: CrewDocumentFilesEndPoints.forDocumentUrl.add('3').toString(),
        method: HttpMethods.GET,
        response: [
            {
                id: 1,
                documentTypeFileId: 3,
                guid: '86c7e86c-3971-441d-b40f-bbfdab55c3d2'
            }
        ]
    },
    {
        url: CrewDocumentFilesEndPoints.controllerUrl.add('4').toString(),
        method: HttpMethods.GET,
        response: []
    },
    {
        url: CrewDocumentFilesEndPoints.controllerUrl.toString(),
        method: HttpMethods.POST,
        response: {
            id: 1,
            guid: '86c7e86c-3971-441d-b40f-bbfdab55c3d2'
        },
    },
    {
        url: CrewDocumentFilesEndPoints.controllerUrl.add('1').add('delete').toString(),
        response: {},
    },
    {
        url: DocumentTypeFilesEndPoints.forDocumentUrl.add('1').toString(),
        method: HttpMethods.GET,
        response: [
            {
                id: 1,
                label: 'Test document image',
                description: 'Test document image description',
                documentTypeId: 1,
                mimeType: 'image/jpeg'
            },
            {
                id: 2,
                label: 'Test document pdf',
                description: 'Test document image description',
                documentTypeId: 1,
                mimeType: 'application/pdf'
            }
        ]
    },
    {
        url: DocumentTypeFilesEndPoints.controllerUrl.toString(),
        method: HttpMethods.POST,
        response: {
            id: 1,
            label: 'Test document image',
            description: 'Test document image description',
            documentTypeId: 1,
            mimeType: 'image/jpg'
        }
    },
    {
        url: DocumentTypeFilesEndPoints.controllerUrl.add('1').add('delete').toString(),
        method: HttpMethods.POST,
        response: 'file deleted'
    },
    {
        url: DocumentTypeFilesEndPoints.controllerUrl.add('2').add('delete').toString(),
        method: HttpMethods.POST,
        response: 'file deleted'
    },
    {
        url: DocumentsAimsEndPoints.categoriesUrl.toString(),
        method: HttpMethods.GET,
        response: [
            {
                categoryId: 1,
                categoryName: 'Category QWERTY'
            },
            {
                categoryId: 2,
                categoryName: 'Category ASDFGH'
            },
            {
                categoryId: 3,
                categoryName: 'Category ZXCVBN'
            }
        ]
    },
    {
        url: DocumentSpecializationEndPoints.getAllDocumentSpecializations.toString(),
        method: HttpMethods.GET,
        response: [
            {
                id: 1,
                specialzationName: 'Specialization QWERTY'
            },
            {
                id: 2,
                specialzationName: 'Specialization ASDFGH'
            },
            {
                id: 3,
                specialzationName: 'Specialization ZXCVBN'
            }
        ]
    },
    {
        url: CrewDocumentDetailEndPoints.getDocumentDetailById.add('5bb80a25-d9cb-4919-abeb-f1f54b9ce065').toString(),
        method: HttpMethods.GET,
        response:
        {
            "files": [
                {
                    "id": 1196,
                    "documentGroupId": "123abcdf-abcd-1234-abcd-abcd1234abcd",
                    "documentTypeFileId": 133,
                    "filePath": null,
                    "guid": "86c7e86c-3971-441d-b40f-bbfdab55c3d2" // Mocked file guid
                },
                {
                    "id": 1197,
                    "documentGroupId": "123abcdf-abcd-1234-abcd-abcd1234abcd",
                    "documentTypeFileId": 134,
                    "filePath": null,
                    "guid": "86c7e86c-3971-441d-b40f-bbfdab55c3d2" // Mocked file guid
                }
            ],
            "documentType": {
                "id": 1157,
                "active": true,
                "name": "DNI",
                "isDocumentNumberRequired": true,
                "isExpeditionDateRequired": true,
                "isExpireDateRequired": true,
                "isExpeditionCountryRequired": true,
                "daysUntilExpirationAlert": 280,
                "expirationReminder": 5,
                "crewRanks": null,
                "bases": null,
                "relatedExpiry": "",
                "aimsFolder": null,
                "aimsCategoryId": null,
                "documentTypeFiles": [
                    {
                        "id": 133,
                        "documentTypeId": 1157,
                        "label": "Frontal",
                        "description": "Captura frontal del documento",
                        "mimeType": "image/jpeg"
                    },
                    {
                        "id": 134,
                        "documentTypeId": 1157,
                        "label": "Dorsal",
                        "description": "Captura dorsal del documento",
                        "mimeType": "image/jpeg"
                    }
                ]
            },
            "id": 1,
            "crewId": 1004,
            "documentTypeId": 1157,
            "documentGroupId": "5bb80a25-d9cb-4919-abeb-f1f54b9ce065",
            "documentNumber": "Y882823R",
            "message": null,
            "expeditionDate": "2023-11-13T00:00:00",
            "expireDate": "2033-11-17T00:00:00",
            "creationDate": "2023-11-15T16:03:05.47",
            "updateDate": "2023-11-15T16:16:47.623",
            "expeditionCountry": "Albania",
            "documentStatus": 5,
            "crewInfo": {
                "crewId": 1004,
                "alias": "ALB1004",
                "rank": "CP",
                "baseId": "ALC",
                "fullName": "Name1004"
            }
        }
    }
];
